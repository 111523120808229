export function setCookie(cname, cvalue, exdays, domain, path) {
  let cookie = cname + '=' + cvalue + ';path=' + (path || '/')

  if (typeof exdays === 'number' && exdays >= 1) {
    cookie = cookie + ';max-age=' + parseInt(Math.floor(exdays * 24 * 60 * 60))
  }

  if (domain) {
    cookie += ';domain=' + domain
  }

  document.cookie = cookie
}

export function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
